import React, { useState, useRef, useEffect, lazy, Suspense } from 'react';
import { Element, Link } from 'react-scroll';
import './styles.css';
import { useInView } from 'react-intersection-observer';

const SideMenu = ({ isOpen, toggleMenu }) => (
  <div className={`side-menu ${isOpen ? 'open' : ''}`}>
    <h2>SITE MAP</h2>
    <nav>
      <h3>Guide</h3>
      <ul>
        <li>
          <Link 
            to="section2" 
            smooth={true} 
            spy={true} 
            offset={-70}
            duration={300}
            easing="easeInOutQuad"
            onClick={toggleMenu}
          >
            Section 2
          </Link>
        </li>
        <li>
          <Link 
            to="about" 
            smooth={true} 
            spy={true} 
            offset={-70}
            duration={300}
            easing="easeInOutQuad"
            onClick={toggleMenu}
          >
            About
          </Link>
        </li>
        <li>
          <Link 
            to="contact" 
            smooth={true} 
            spy={true} 
            offset={-70}
            duration={300}
            easing="easeInOutQuad"
            onClick={toggleMenu}
          >
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  </div>
);

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="app-container">
      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>
      <SideMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />
      <main className="content">
        <Element name="section2"><Section2 /></Element>
        <Element name="about"><About /></Element>
        <Element name="contact"><Contact /></Element>
      </main>
    </div>
  );
};

const Section2 = () => (
  <div>
    <div className="section2-title">
      <h1>ADVANCED ANALYTICS ENTERPRISE SOLUTIONS</h1>
    </div>
    <div className="section2-background">
      <VideoPlayer 
        src="heatmap_animation_smooth_v2.mp4" 
        webmSrc="heatmap_animation_smooth_v2.webm"
        fallbackImage="fallback_image.jpg"
        alt="Heatmap Animation" 
        style={{ width: '100%', height: '70vh', objectFit: 'cover' }}
      />
    </div>
    <LogoContainer />
    <BarplotComponent imageUrl="barplot_animation_high_res_1.mp4" webmUrl="barplot_animation_high_res_1.webm" isVideo  />
    <BarplotComponent imageUrl="scatterplot_animation_high_res_1.mp4" webmUrl="scatterplot_animation_high_res_1.webm" isVideo reverse />
    <BarplotComponent imageUrl="circular_heatmap_animation.mp4" webmUrl="circular_heatmap_animation.webm" isVideo  />
  </div>
);

const VideoPlayer = ({ src, webmSrc, fallbackImage, alt, style }) => {
  const videoRef = useRef(null);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isVideoError, setIsVideoError] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const playVideo = () => {
        video.play().catch(error => {
          console.error("Video playback failed:", error);
          setIsVideoError(true);
        });
      };

      video.addEventListener('canplay', () => {
        setIsVideoReady(true);
        playVideo();
      });

      video.addEventListener('error', (e) => {
        console.error("Video error:", e);
        setIsVideoError(true);
      });

      // Attempt to play when component mounts
      playVideo();
    }

    return () => {
      if (video) {
        video.removeEventListener('canplay', () => {});
        video.removeEventListener('error', () => {});
      }
    };
  }, []);

  if (isVideoError) {
    return (
      <img 
        src={fallbackImage} 
        alt={alt} 
        style={{ ...style, display: 'block' }}
      />
    );
  }

  return (
    <>
      {!isVideoReady && <div>Loading...</div>}
      <video 
        ref={videoRef}
        poster={fallbackImage}
        preload="metadata"
        style={{ ...style, display: isVideoReady ? 'block' : 'none' }}
        autoPlay 
        loop 
        muted 
        playsInline
      >
        <source src={webmSrc} type="video/webm" />
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </>
  );
};

const LogoContainer = () => (
  <div className="logo-container">
    {[
      { src: 'logos/airflow_logo_2.png', width: 91, height: 35 },
      { src: 'logos/python_logo_2.png', width: 106, height: 60 },
      { src: 'logos/mlflow_logo_2.png', width: 110, height: 40 },
      { src: 'logos/numpy_logo.png', width: 110, height: 50 },
      { src: 'logos/pandas_logo.png', width: 124, height: 50 },
      { src: 'logos/superset_logo_2.png', width: 154, height: 40 },
      { src: 'logos/pytorch_logo_2.png', width: 120, height: 30 },
    ].map((logo, index) => (
      <div key={index} className="logo-item">
        <img src={logo.src} alt={`Logo ${index + 1}`} width={logo.width} height={logo.height} loading="lazy" />
      </div>
    ))}
  </div>
);

const BarplotComponent = ({ description_list, imageUrl, webmUrl, isVideo = false, reverse }) => {
  const { ref, inView } = useInView({ threshold: 0, triggerOnce: true });

  const description = (
    <div className="description">
      {[
        'Cutting-edge data analytics. We help businesses unlock potential through data.',
        'Bespoke solutions. Specializing in building analytics capabilities for smarter, data-driven decisions.',
        'Innovation & Growth. Paving the way for your business\'s future.',
        'Experienced Guidance. Leveraging years of industry experience, we guide businesses in harnessing their data for optimal results.',
        'Strategic Partnership. Working closely with your team, we navigate complexities to create clear, actionable strategies.',
      ].map((text, index) => (
        <div key={index}>
          <span className="bullet"></span>
          <span className="highlight">{text.split('.')[0]}.</span> {text.split('.').slice(1).join('.')}
        </div>
      ))}
    </div>
  );

  const mediaElement = isVideo ? (
    <VideoPlayer 
      src={imageUrl} 
      webmSrc={webmUrl}
      fallbackImage="fallback_image.jpg"
      alt="Animated Plot" 
      style={{ width: '100%', maxWidth: 500, height: 'auto' }} 
    />
  ) : (
    <img 
      src={imageUrl} 
      alt="Animated Plot" 
      style={{ width: '100%', maxWidth: 500, height: 'auto' }} 
      loading="lazy" 
    />
  );

  return (
    <div ref={ref} className="barplot-container">
      {inView && (reverse ? (
        <>
          {mediaElement}
          {description}
        </>
      ) : (
        <>
          {description}
          {mediaElement}
        </>
      ))}
    </div>
  );
};

const About = () => (
  <div>
    <h2>About Us</h2>
    <p>We are a professional IT data consultancy firm...</p>
  </div>
);

const Contact = () => (
  <div>
    <h2>Contact Us</h2>
    <p>Reach us at...</p>
  </div>
);

export default App;